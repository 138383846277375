import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'gatsby-plugin-intl';
import axios from 'axios';
import './style.scss';

const NumuLegal = ({ language, type }) => {
  const NODE_API = process.env.GATSBY_NODE_API;
  const [legalData, setLegalData] = useState({});

  const getTerms = async () => {
    const legal = await axios.get(`${NODE_API}/jobs/get-cookies/${type}`);
    if (legal && legal.data.data) {
      setLegalData(legal.data.data.find((x) => x.languages === language));
    }
  };

  useEffect(() => {
    getTerms();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="container">
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: legalData.content
        }}
        className="legal-style"
      />
    </div>
  );
};

NumuLegal.propTypes = {
  type: PropTypes.string.isRequired,
  language: PropTypes.string.isRequired
  // formatMessage: PropTypes.func.isRequired
};

export default injectIntl(NumuLegal);
